<template>
<div class="contactsbox">
  <v-img :src="logo" contain class="logo" @click="$router.push('/main')" alt="Свое"/>
  <span>Москва, Гоголевский бульвар, 17, офис 300</span>
  <a href="tel:+74956371249">+7 495 637-12-49</a>
  <a href="mailto:info@fondsvoe.ru">info@fondsvoe.ru</a>
</div>
</template>

<script>
import logo from '@/assets/img/footerlogo.svg'
export default{
  name: 'ContactsExtBlock',
  data: () => ({
    logo
  })
}
</script>

<style lang="scss">
.contactsbox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  padding: 120px 100px 0;
  @media (max-width: 1350px) {
    padding: 120px 0 0;
  }
  @media screen and (max-width: 1100px) {
    padding: 0;
  }
  @media screen and (max-width: 576px) {
    display: block;
  }
  .logo{
    max-width: 204px;
    min-width: 204px;
    .v-image__image{
      max-width: 204px;
    }
    @media (max-width: 1100px) {
      max-width: 50%;
      min-width: 50%;
    }
    @media (max-width: 576px) {
      max-width: 100%;
      min-width: 100%;
    }
  }
  span{
    display: block;
    @media (max-width: 1100px) {
      width: 50%;
      text-align: right;
    }
    @media (max-width: 576px) {
      width: 100%;
      text-align: left;
    }
  }
  a{
    display: block;
    color: $text!important;
    text-decoration: none;
    transition: color .35s ease;
    &:hover{
      color: $green!important;
    }
  }
}
</style>
