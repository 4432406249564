<template>
  <section class="block block-padding-right padding-mobile-none">
    <div class="wrap__farm-application">
      У вас своя ферма?<br />Оставьте заявку и возможно следующий выпуск будет про вас!
      <router-link class="farm-application__button" to="/form">Оставить заявку</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "FormBlock"
}
</script>

<style >
.wrap__farm-application{padding:40px 20px; background:#F0F0F0 url("./../assets/img/bg-caltoaction.png") no-repeat center left; font-weight: 600;font-size: 23px;line-height: 30px;color: #343434; text-align:center; border-radius:20px}
.farm-application__button{width: 100%; max-width: 340px; line-height: 70px;background: #13A538;border-radius: 10px;font-weight: 600;font-size: 20px;text-align: center;color: #FFFFFF;display:block;text-decoration:none;margin:40px auto 0}
.farm-application__button:hover{background:#FFD400}
@media(max-width:1100px){
  .wrap__farm-application{font-size: 20px; line-height: 28px}
  .farm-application__button{font-size: 18px; line-height: 60px;}
}
@media(max-width:991px){
  .wrap__farm-application{font-size:18px}
  .farm-application__button{font-size: 16px; line-height: 50px;}
}
@media(max-width:767px){
  .wrap__farm-application{font-size:16px; line-height: 26px}
  .farm-application__button{font-size: 14px;line-height: 46px;}
  .farm-application__button{max-width: 320px}
}
@media(max-width:575px){
  .wrap__farm-application{border-radius:14px; font-size: 14px; line-height: 24px; background:#F0F0F0 url(/src/assets/img/bg-caltoaction-mobile.png) no-repeat center left / cover}
  .farm-application__button{font-size: 13px; line-height: 44px}
}
</style>
